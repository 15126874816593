import ApiService from "@/core/services/ApiService";
import { FormData } from "@/core/types/gws-master/master/settlement/FormData";
import { ApiResponse, Meta } from "@/core/types/misc/ApiResponse";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { defineStore } from "pinia";

export const useSettlementFormStore = defineStore({
  id: "settlementFormStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateFormData: {} as FormData,
      stateError: false,
      stateErrors: [] as string[],
      stateErrorMsg: "",
      stateUpdateId: "",
    };
  },
  getters: {
    loading(state) {
      return state.stateIsLoading;
    },
    formData(state) {
      return state.stateFormData;
    },
    error(state) {
      return state.stateError;
    },
    errors(state) {
      return state.stateErrors;
    },
    errorMessage(state) {
      return state.stateErrorMsg;
    },
  },
  actions: {
    async updateData() {
      this.stateIsLoading = true;
      try {
        const payload = {
          payment_date: this.formData.payment_date,
          status: this.formData.status,
        };
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/settlements/${this.stateUpdateId}`,
            payload as AxiosRequestConfig
          );
        if (response.status === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },

    setFormData(data: FormData) {
      this.stateFormData = {
        payment_date: data.payment_date || "",
        status: data.status || "",
      };
    },
    setErrors(errors: string[]) {
      this.stateErrors = errors;
    },
  },
});
