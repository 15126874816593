import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-51b88bcc")
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "d-flex gap-2" }
const _hoisted_3 = ["id"]
_popScopeId()


import { simpleFormatDate2 } from "@/core/helpers/date-format";
import { useSettlementListStore } from "@/store/pinia/gws-master/master-data/settlement/useSettlementListStore";
import { useSettlementFormStore } from "@/store/pinia/gws-master/master-data/settlement/useSettlementFormStore";
import { ListItem } from "@/core/types/gws-master/master/settlement/ListItem";
import { checkButtonPermission } from "@/core/helpers/helpers-utils";


export default _defineComponent({
  props: {
  listData: {
    required: true,
    type: Array,
  },
  page: {
    required: true,
    type: Number,
    default: 1,
  },
},
  emits: ["edit-data"],
  setup(__props, { emit: emits }) {

const props = __props


const listStore = useSettlementListStore();
const formStore = useSettlementFormStore();


const handleEditData = (item: ListItem) => {
  const editRow = {
    payment_date: item.payment_date,
    status: item.status,
  };
  formStore.setFormData(item);
  emits("edit-data", item.id);
};

return (_ctx: any,_cache: any) => {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return (_unref(listStore).loading)
    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, null, 512)), [
        [_directive_loading, _unref(listStore).loading]
      ])
    : _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        key: 1,
        data: props.listData,
        "empty-text": 'No Data',
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "no",
            type: "index",
            label: "No.",
            "min-width": "20",
            align: "center"
          }),
          _createVNode(_component_el_table_column, {
            prop: "no_settlement",
            label: "No.Settlement"
          }),
          _createVNode(_component_el_table_column, {
            prop: "payment_date",
            label: "Pembayaran"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(_unref(simpleFormatDate2)(scope.row.payment_date)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "month",
            label: "Tagihan Selesai"
          }),
          _createVNode(_component_el_table_column, {
            prop: "company",
            label: "Nama Perusahaan"
          }),
          _createVNode(_component_el_table_column, {
            prop: "transaction",
            label: "Transaksi"
          }),
          _createVNode(_component_el_table_column, {
            prop: "fee",
            label: "Fee"
          }),
          _createVNode(_component_el_table_column, {
            prop: "income",
            label: "Pendapatan"
          }),
          _createVNode(_component_el_table_column, {
            prop: "status",
            label: "Status"
          }, {
            default: _withCtx((scope) => [
              (scope.row.status === 'PAID')
                ? (_openBlock(), _createBlock(_component_el_tag, {
                    key: 0,
                    class: "ml-2",
                    type: "success"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(scope.row.status), 1)
                    ]),
                    _: 2
                  }, 1024))
                : (_openBlock(), _createBlock(_component_el_tag, {
                    key: 1,
                    class: "ml-2",
                    type: "danger"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(scope.row.status), 1)
                    ]),
                    _: 2
                  }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "action",
            label: "Aksi"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", _hoisted_2, [
                (_unref(checkButtonPermission)('U'))
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "btn-edit",
                      id: scope.id
                    }, [
                      _createVNode(_component_inline_svg, {
                        src: `media/gws/pencil-alt.svg`,
                        onClick: ($event: any) => (handleEditData(scope.row))
                      }, null, 8, ["src", "onClick"])
                    ], 8, _hoisted_3))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"])), [
        [_directive_loading, _unref(listStore).loading]
      ])
}
}

})