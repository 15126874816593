import { defineStore } from "pinia";
import PaginationType from "@/core/types/misc/Pagination";
import ApiService from "@/core/services/ApiService";
import { AxiosResponse } from "axios";
import { FilterData } from "@/core/types/misc/FilterData";
import { ListItem } from "@/core/types/gws-master/master/settlement/ListItem";
import { ApiResponse } from "@/core/types/misc/ApiResponse";
import { Option } from "@/core/types/misc/Option";
export const useSettlementListStore = defineStore({
  id: "settlementListStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateFilterData: {
        page: 1,
        limit: 10,
        dir: "DESC",
        sort: "trx_date",
      } as FilterData,
      statePagination: new PaginationType(),
      stateDisplayData: [] as ListItem[],
      stateCompanyOption: [] as Option[],
    };
  },
  getters: {
    loading(state) {
      return state.stateIsLoading;
    },
    displayData(state) {
      return state.stateDisplayData;
    },
    companyOption(state) {
      return state.stateCompanyOption;
    },
  },
  actions: {
    async getData(isPageRefresh = true) {
      const params = {
        page: this.stateFilterData.page.toString() || "",
        limit: this.stateFilterData.limit.toString() || "",
        dir: this.stateFilterData.dir || "",
        sort: this.stateFilterData.sort || "",
      };
      if (this.stateFilterData.search) {
        params["search"] = this.stateFilterData.search;
      }
      try {
        if (isPageRefresh) this.stateIsLoading = true;
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `settlements?${new URLSearchParams(params).toString()}`
        );

        this.stateDisplayData = response.data.data;

        this.setTotalPage(response.data.meta.pagination.count);
      } catch (error) {
        console.log(error);
      }
      this.stateIsLoading = false;
    },

    async setPage(newPage: number) {
      this.statePagination.handleCurrentPageChange(newPage);
      this.statePagination.totalPageSize = this.stateFilterData.limit;
      this.stateFilterData.page = this.statePagination.currentPage;
      await this.getData();
    },
    async setPageSize(size: number) {
      this.stateFilterData.limit = size;
      this.statePagination.totalPageSize = this.stateFilterData.limit;
      this.stateFilterData.page = this.statePagination.currentPage;
      await this.getData(true);
    },
    setTotalPage(totalPage: number) {
      this.statePagination.totalPage = totalPage;
      this.statePagination.getPaginationStartIndex();
      this.statePagination.getPaginationLastIndex();
    },
  },
});
